import React from "react";
import { Admin, Resource } from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";

import authProvider from "authProvider";

import {
  DailyDrawChannelsList,
  DailyDrawChannelCreate,
  DailyDrawChannelEdit,
} from "components/DailyDrawChannels";
import LoginPage from "components/LoginPage";
import { getApiUrl } from "./util";

const App = () => {
  const dataProvder = simpleRestProvider(getApiUrl());
  return (
    <Admin
      loginPage={LoginPage}
      authProvider={authProvider}
      dataProvider={dataProvder}
    >
      <Resource
        name="704111043789717627/dailydraw/channels"
        options={{ label: "Daily Draw Channels" }}
        list={DailyDrawChannelsList}
        create={DailyDrawChannelCreate}
        edit={DailyDrawChannelEdit}
      />
    </Admin>
  );
};

export default App;
