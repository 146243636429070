import React from "react";
import {
  Create,
  CreateProps,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  EditProps,
  List,
  ListProps,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { transformEditData } from "../util";

export const DailyDrawChannelsList = (props: ListProps) => {
  return (
    <List {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="discord_channel_name" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const DailyDrawChannelCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="discord_channel_name" />
      </SimpleForm>
    </Create>
  );
};

export const DailyDrawChannelEdit = (props: EditProps) => {
  return (
    <Edit transform={transformEditData} {...props}>
      <SimpleForm>
        <TextInput source="discord_channel_name" />
      </SimpleForm>
    </Edit>
  );
};
