import { Record } from "ra-core";

const EXCLUDED = ["id", "discord_guild_id"];

export const transformEditData = (data: Record) => {
  return Object.fromEntries(
    Object.entries(data).filter(([key]) => !EXCLUDED.includes(key))
  ) as Record;
};

export const getApiUrl = (route: string = "") => {
  return process.env.REACT_APP_API_URL + route;
};
