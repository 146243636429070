import * as React from "react";
import { Notification, defaultTheme } from "react-admin";
import { ThemeProvider, makeStyles } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { FaDiscord } from "react-icons/fa";

import { getApiUrl } from "../util";

const useStyles = makeStyles((theme) => ({
  fullScreen: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  centeredCard: {
    width: 512,
    height: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  discordButton: {
    color: "white",
    background: "#5865F2",
  },
}));

const LoginPage = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <Box className={classes.fullScreen}>
        <Card className={classes.centeredCard}>
          <img
            src={`${process.env.PUBLIC_URL}/logo512.png`}
            alt="PogBot Logo"
            width={400}
            height={400}
          />
          <Button className={classes.discordButton} href={getApiUrl("/login")}>
            {FaDiscord} Login with Discord
          </Button>
        </Card>
      </Box>
      <Notification />
    </ThemeProvider>
  );
};

export default LoginPage;
